<template>
	<div>
		<v-card class="elevation-6 card-login">
			<v-card-text class="pa-0">
				<v-row>
					<v-col cols="12" class="px-11 py-5">
						<v-row justify="center" class="text-center">
							<v-col cols="4" class="d-felx justify-center">
								<v-avatar :color="verificate ? 'info' : 'error'" size="160">
                  <v-icon dark size="140" v-text="verificate ? 'mdi-check' : 'mdi-close'"></v-icon>
                </v-avatar>    			
							</v-col>
						</v-row>
						<v-row justify="center">
							<v-col cols="12" class="py-6 px-10 text-center">										
								<h2 class="primary--text py-1 text-uppercased" v-text="msg"></h2>							
							</v-col>
						</v-row>
						<v-row justify="center" class="text-center">
							<v-col cols="6" class="px-10">
								<v-btn block large color="primary" @click="$router.push({name: 'login'})">Autenticar</v-btn>            			
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</div>
</template>
<script>
export default {
	data() {
		return {			
			verificate: '',
			msg: '',
		}
	},
	created(){
		this.verificate_account();
	},
	methods: {
		async verificate_account(){
			this.$store.commit('SET_OVERLAY', true);
			try{
				const { data } = await this.$api.get(`api/auth/register/verify/${this.$route.params.code}`);
				this.msg = data.message;
				this.verificate = true;
			}
			catch(e){
				this.msg = e.response.data.message;
				this.verificate = false;
			}
			finally{
				this.$store.commit('SET_OVERLAY', false);
			}
		},
	},
}
</script>

